<template>
  <div style="display: flex; flex-direction: column;">
    <audio ref="track" @timeupdate="onTimeUpdate" />
    <el-table v-loading="loading" :data="items" :loading="loading" class="track-table">
      <el-table-column>
        <template #default="scope">
          <template v-if="!scope.row.__wasPlaing ">
            <div @click="playTrack(scope.row)">
              <el-icon size="30" style="width:1em;height:1em">
                <Play />
              </el-icon>
            </div>
          </template>
          <template v-else>
            <div @click="stopTrack(scope.row)">
              <el-icon size="30" color="#3290FF">
                <VideoPause />
              </el-icon>
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="order" label="№" />
      <el-table-column prop="name" label="Название" />
      <el-table-column prop="version" label="Версия" />
      <el-table-column prop="headArtists" label="Артист">
        <template #default="scope">
          <span>
            {{ getPseudonym(scope.row.headArtists) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="collaborators" label="Доп. артист">
        <template #default="scope">
          <span>
            {{ getPseudonym(scope.row.collaborators) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="isrc" label="ISRC" />
      <el-table-column prop="hasProfanity" label="Лексика">
        <template #default="scope">
          {{ scope.row.hasProfanity ? "Да" : "Нет" }}
        </template>
      </el-table-column>
      <el-table-column prop="author" label="Автор" />
      <el-table-column prop="composer" label="Композитор" />
    </el-table>
    <div v-if="playingTrack.id" class="player__wrapper">
      <div class="player">
        <div style="display: flex; align-items: center;">
          <div class="mr3" style="flex-basis:300px">
            {{ playingTrack.name }}
          </div>
          <el-icon v-if="!wasTrackPlaying" size="30" style="width:1.6666666666666667em;height:1.6666666666666667em" @click="$refs.track.play(); wasTrackPlaying = true;">
            <Play />
          </el-icon>
          <el-icon v-else size="30" style="width:1.6666666666666667em;height:1.6666666666666667em" color="#3290FF" @click="$refs.track.pause(); wasTrackPlaying = false;">
            <VideoPause />
          </el-icon>
        </div>
        <div style="width:100%; padding-left:1.1111111111111112em">
          <el-slider v-model="playingTrack.currentTime" :show-tooltip="false" :max="playingTrack.duration" @change="setCurrentTime()" />
          <div style="display: flex; flex-direction: row;justify-content: space-between;">
            <div>
              {{ formatCurrentTime }}
            </div>
            <div>
              {{ formatDurationTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import {
  Play,
  // Delete,
  // Download,
} from '@/assets/icons/index.js';
import {
  ElMessage,
} from 'element-plus';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: 'TrackTable',
  components: {
    Play,
    // Delete,
    // Download,
  },
  props: {
    id: String,
  },
  data: () => ({
    editedTrack: null,
    wasTrackPlaying: false,
    playingTrack: {
      id: null,
      name: null,
      duration: null,
      currentTime: null,
    },
    loading: true,
    items: [],
    dialogVisible: false,
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
    pageSize: 1,
  }),
  mounted() {
    this.getTrackList();
  },
  methods: {
    getPseudonym(item) {
      return item.map((it) => it.pseudonym).join(', ');
    },
    pageChange(page) {
      this.pageNumber = page;
      this.getTrackList();
    },
    setCurrentTime() {
      this.$refs.track.currentTime = this.playingTrack.currentTime;
    },
    async downloadTrack(track) {
      const { data, headers } = await this.$http({
        method: 'GET',
        responseType: 'blob',
        url: track.audioUrl,
      });
      const filename = `${track.techName}.wav`;
      const objectUrl = window.URL.createObjectURL(new Blob([data]), { type: headers['content-type'] });
      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(objectUrl);
    },
    async stopTrack(trackItem) {
      const track = this.$refs.track;
      track.pause();
      trackItem.__wasPlaing = false;
      this.wasTrackPlaying = false;
    },
    async playTrack(trackItem) {
      const track = this.$refs.track;
      trackItem.__wasPlaing = true;
      if (this.playingTrack.id !== trackItem.id) {
        this.playingTrack.name = trackItem.name;
        this.playingTrack.id = trackItem.id;
        this.playingTrack.duration = track.duration;
        this.playingTrack.currentTime = track.currentTime;
        track.src = trackItem.audioUrl;
      }
      this.wasTrackPlaying = true;
      track.play();
    },
    async deleteReleaseTrack(id) {
      await this.$axios.delete(`/tracks/delete-track-by-id/${id}`)
        .then(() => {
          ElMessage({ message: 'Трек успешно удалён', type: 'success' });
          this.getTrackList();
        })
        .catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        });
    },
    async setOrder() {
      const items = this.orderedItems.map((it) => {
        const { id, order } = it;
        return {
          trackId: id,
          order,
        };
      });
      const res = {
        items,
      };
      await this.$axios.put('/tracks/update-tracks-order', res);
    },
    async onTimeUpdate() {
      const track = this.$refs.track;
      this.playingTrack.duration = track.duration;
      this.playingTrack.currentTime = track.currentTime;
    },
    async getTrackList() {
      this.loading = true;
      const { data } = await this.$axios.get(`tracks/get-tracks-by-release-id/${this.id}`, {});
      this.items = data.map((it,i) => it = { // eslint-disable-line
        ...it,
        order: i + 1,
        __wasPlaing: false,

      });
      this.$emit('has-track', this.items.length > 0);
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(['isModerator']),

    debouncedSetOrder() {
      return debounce(this.setOrder, 500);
    },

    orderedItems() {
      const it = this.items;
      return it.sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      });
    },
    formatDurationTime() {
      const minutes = Math.floor(this.playingTrack.duration / 60);
      let seconds = Math.floor(this.playingTrack.duration - minutes * 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes || 0}:${seconds || '00'}`;
    },
    formatCurrentTime() {
      const minutes = Math.floor(this.playingTrack.currentTime / 60);
      let seconds = Math.floor(this.playingTrack.currentTime - minutes * 60);
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes || 0}:${seconds || '00'}`;
    },
  },
};
</script>

<style lang="scss">

.player{

background: $primaryWhite;
width:900px;
padding: 0.5555555555555556em 1.6666666666666667em;
margin: 0 auto;
border-radius: 1.6666666666666667em;
display: flex;

&__wrapper{
  z-index: 40;
  background:  $primaryWhite;
  width: 100%;
  margin-left: -355px ;
  bottom: 0;

position: fixed;
}
}
</style>
