<template>
  <div>
    <div v-if="release" class="realese_page__info-wrapper" style="display:flex; flex-direction:row">
      <div style="width:100%">
        <div class="realese_page__header">
          <el-image :src="release.imageThumbPath|| release.imagePath">
            <template #error>
              <div class="image-slot">
                <el-icon size="1.4em">
                  <Catalog />
                </el-icon>
              </div>
            </template>
          </el-image>
          <div class="realese_page__header--info ml3 mv3">
            <div class="realese_page__header--name">
              {{ release.name || 'Нет названия' }}
            </div>
            <div class="realese_page__header--artist">
              {{ release.headArtists.join(', ') || 'Нет исполнителя' }}
            </div>
            <div class="realese_page__header--type">
              {{ realeseType[release.type] }}
            </div>
          </div>
        </div>
        <el-row class="mt4 realese_page__data">
          <el-col :span="12">
            <div class="realese_page__data--fields mb4">
              <div>
                Жанр
              </div>
              <div>
                {{ getGenreFullName(release.genre) }}
              </div>
            </div>
            <div class="realese_page__data--fields">
              <div>
                Дата релиза
              </div>
              <div v-if="release.releaseDate">
                {{ $moment(release.releaseDate ).format('YYYY.MM.DD') }}
              </div>
              <div v-else>
                {{ LONG_DASH }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="realese_page__data--fields mb4">
              <div>
                UPC
              </div>
              <div>
                {{ release.upc || LONG_DASH }}
              </div>
            </div>
            <div class="realese_page__data--fields">
              <div>
                Лейбл
              </div>
              <div :title="release.copyright">
                {{ release.copyright || LONG_DASH }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="display: flex; flex-direction: row;">
        <div class="realese_page__btn" @click="changeReleaseModal=true">
          <el-icon size="30" style="width:1em;height:1em">
            <Pen />
          </el-icon>
        </div>
        <div class="realese_page__btn">
          <el-icon size="40" style="width:1em;height:1em">
            <Copy />
          </el-icon>
        </div>
        <div class="realese_page__btn" @click="deleteRelease()">
          <el-icon size="35" style="width:1em;height:1em">
            <Delete />
          </el-icon>
        </div>
      </div>
    </div>
    <TrackTable :id="id" class="mb6" />
    <ChangeReleaseStatusModal :id="id" v-model="changeReleaseModal" />
  </div>
</template>

<script>
import {
  ElMessage, ElMessageBox,
} from 'element-plus';
import {
  Pen,
  Copy,
  Delete,
} from '@/assets/icons/index.js';
import { realeseType } from '@/utilities/enums';
import TrackTable from '../components/tables/TrackTable.vue';
import ChangeReleaseStatusModal from '../components/modals/ChangeReleaseStatusModal.vue';

export default {
  name: 'Release',
  props: {
    id: String,
  },
  data: () => ({
    realeseType,
    release: null,
    changeReleaseModal: false,
  }),
  mounted() {
    this.getRelease();
  },
  methods: {
    async deleteRelease() {
      await ElMessageBox.confirm(
        'Вы хотите отправить заявку на удаление?',
        'Warning',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning',
        },
      );
      try {
        await this.$axios.post(`/release-catalog/move-release-delete/${this.id}`);
        ElMessage({
          message: 'Заявка на удаление отправлена', type: 'success',
        });
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
    getGenreFullName(genre) {
      if (!genre) {
        return '—';
      }
      return `${genre.parent ? `${genre.parent.name} / ` : ''}${genre.name}`;
    },
    async getRelease() {
      try {
        const { data } = await this.$axios.get(`/release-catalog/get-release-draft-by-id/${this.id}`);
        this.release = data.data;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      }
    },
  },
  components: {
    TrackTable,
    Pen,
    Copy,
    Delete,
    ChangeReleaseStatusModal,
  },
};
</script>

<style lang="scss">

.realese_page {
  &__info-wrapper {
    position: relative;
    background-color: $primaryWhite;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2em 2em;
    border-radius: 2em;
    margin-bottom:1em;
  }
  &__header {
    display: flex;
    flex-direction: row;

    .el-image{
      width: 16em !important;
      height:16em !important;
      border-radius:1em!important;

      .image-slot{
        background-color: #151515;
        width:100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &--info{
      width:calc(100% - 170px);
      padding: 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &--name{
      display: flex;
      align-items: center;
      font-size: 2.5em !important;
      width:calc(100% - 5.555555555555555em);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      font-weight: 500;
    }

    &--artist{
      display: flex;
      align-items: center;
      font-size: 1.35em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
    &--type{
      display: flex;
      align-items: center;
      font-size: 1em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      font-weight: 300;
    }
  }
  &__btn{
      margin-left: 0.3rem;
      padding: 0.25rem;
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      width:2rem;
      height:2rem;
      border-radius: 50%;
      font-weight:500;

      .el-icon{
        color: $primaryBlue;
        font-size:1.3rem!important;
      }

      &:hover{
        background-color: rgba(255,255,255,.2);
      }
    }
  &__data {
    width:70%;

    &--fields{
      font-size: 1em;
      display: flex;
      flex-direction: row;

      & :first-child{
        width:4em;
        margin-right: 0.5em;
        color: $primaryBlue;
      }
      & :last-child{
        font-weight: 300;
        margin-left: 0.5em;
      }
    }
  }
}
</style>
