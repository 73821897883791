<template>
  <div>
    <el-dialog
      v-model="visible"
      :title="header"
      class="purchase-modal"
      style="width:40em"
      @close="close"
    >
      <template #header="{ }">
        <div>
          {{ header }}
        </div>
      </template>
      <div style="width:100%">
        <el-form>
          <el-form-item :error="getErrorMessage('comment')">
            <el-input v-model="form.comment" type="textarea" :rows="5" placeholder="Комменатарий" @blur="v$.form.comment.$touch()" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="purchase-modal__btn">
          <el-button type="primary" :disabled="allowSubmit" :loading="loading" @click="toChange">
            Отправить
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { required, minLength, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import {
  ElMessage,
} from 'element-plus';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: 'ChangeReleaseStatusModal',
  props: {
    id: String,
    modelValue: Boolean,
    staus: String,
  },
  data: () => ({
    allow: false,
    loading: false,
    form: {
      comment: null,
    },
  }),
  computed: {
    header() {
      return this.status === 'delete' ? 'Запрос на удаление релиза' : 'Запрос на изменение релиза';
    },
    allowSubmit() {
      return this.v$.$invalid;
    },
    visible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    async toChange() {
      this.loading = true;
      try {
        const params = { ...this.form };
        await this.$axios.post(`/release-catalog/move-release-edit/${this.id}`, params);
        this.close();
        ElMessage({ message: 'Заявка отправлена', type: 'success' });
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    getErrorMessage(fieldName) {
      return this.$errorsMessages(this.v$, `form.${fieldName}`);
    },
    close() {
      this.visible = false;
      this.form = {
        comment: null,
      };
    },
  },
  validations() {
    return {
      form: {
        comment: { required, minLength: minLength(5), maxLength: maxLength(2000) },
      },
    };
  },

};
</script>

<style lang="scss">
  .purchase-modal{

    &__btn{

    }
    .el-dialog{
      &__header{
        font-size: 1.7777777777777777em;
        padding:1.6666666666666667em 2.5em  0.8333333333333334em 2.5em!important;
        text-align: left !important;
        border-bottom: $whiteOpacity 1px solid;
        margin:0;

        &btn{
          font-size: 0.8em;
          top: 26px !important;
          right: 1.6666666666666667em !important;
        }
      }

      &__body{
        // padding:1.1111111111111112em 2.5em 1.1111111111111112em 1.6666666666666667em!important;
        // border-bottom: $whiteOpacity 1px solid;
      }

      &__footer{
        // padding:1.1111111111111112em 2.5em 1.6666666666666667em 1.6666666666666667em!important;
      }
    }
  }
</style>
